import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faYoutube, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'
import blacklogo from '../../assets/LogoBlack.gif'
import whitelogo from '../../assets/LogoWhite.gif'
import { isSafari } from 'react-device-detect';
const Navbar = (props) => {
  const {colortheme,page,hidelogo} = props;
  const [popupStatus,setPopupStatus] = useState(false)
  const [navStatus, setNavStatus] = useState(false)
  const [subheight,setSubheight] = useState("99vh");
  useEffect(() => {
    if(isSafari){
      setSubheight("98vh")
    }
    else{
      setSubheight("99vh")
    }
    },[]);
  return (
    <>
     <div id="navbar" className={`flex fixed top-0 z-[49] w-full   lg:hidden h-[130px] justify-between px-8 py-8 ${colortheme == "" ? '' : ''} `}>
     <button id="menuBtn" type="button" onClick={()=>setNavStatus(!navStatus)}
        className="  inline-flex items-center justify-center ">
        <span className="sr-only">Open main menu</span>
        <svg className="h-12 w-12 lg:h-16 lg:w-16 "  fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
          aria-hidden="true">
          <path strokeLinecap=""  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
        
      </button>

      
      <Link to={"/"}>
      
      {(colortheme == "White" ) ?
     <img
     src={blacklogo}
     alt="Uppout"
     className="w-[45px]"
     width={45}
     height={0}
     
   />
     
      :

      <img
      src={whitelogo}
      alt="Uppout"
      className={`w-[45px] ${hidelogo ? 'hidden' :''}`}
      width={50}
      height={0}
      
    />

    }

         </Link>
     </div>
   
   
     <div id="sidebar" role="dialog" className={`lg:hidden fixed duration-300 h-screen ease-in-out  delay-100   z-50  w-2/3 top-0 overflow-y-auto bg-[#000] sm:max-w-full sm:ring-1 sm:ring-gray-900/10 ${navStatus ? 'left-0' : '-left-[110%]'} mobilesidebar`}>
     <div className={`${isSafari ? 'h-[90vh]' : 'h-[99vh]'}    flex flex-col  justify-between`}>
    <div className='w-full px-8 '>
    <button id="closeSidebarBtn" onClick={()=>setNavStatus(!navStatus)} type="button" className={` rounded-md p-0 mt-8 group ${colortheme == "White" ? "text-[#fff] " : "text-[#000] " }`}>
            <svg className="h-16 w-16 group-hover:rotate-180 transition duration-300 ease-in-out" fill="#fff"
              viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
    </div>

<div className="pb-20 px-8">

<ul className=" font-semibold mobsidemenu ">
  <li className="h-[80px] text-[1.1em]  ">
    <Link to={"/about"} className={`menuitem block w-[170px] pl-2 min-h-[70px] text-xl leading-tight    ${colortheme == "White"? 'text-white' : 'text-black'} ${page == "about"?"active":""}`}>QUEM <span className={`${page == "about"?"hidden":"block"}`} ></span> SOMOS</Link>
    </li>
  <li className='h-[80px] text-[1.1em] relative'>
  <Link href={''}  className={`menuitem block  w-[170px]  pl-2  min-h-[40px]  text-xl leading-tight    ${colortheme == "White"? 'text-white' : 'text-black'}  ${popupStatus? "text-themeblue active" : ""} ` } onClick={()=>{setPopupStatus(!popupStatus)}} >VAMOS <br className={`hoverremove ${popupStatus ? "!hidden " : ""} `} /> FALAR</Link>

  {popupStatus ?
<div className={`absolute left-2 top-[65%] lg:top-[55%] text-[0.7em] font-normal italic ${colortheme == "White"? 'text-white' : 'text-black'}`}>
<a href="mailto:geral@uppout.com">geral@uppout.com</a> <br /><br />
<a href="tel:+351215862031">+351 215 862 031</a>
<br /><br />
<a href="https://maps.app.goo.gl/rwn3PVecz5K6GfFUA" rel="nofollow" target='_blank'>
Rua Garridas 8,<br />
1500- 618 Lisboa
</a>


</div>  
:""
}
    </li>
</ul>
</div>
<div className="pb-2 mx-10">
  <ul className="flex justify-center items-center mb-8 ">
  <li><a href="https://www.youtube.com/channel/UC4MyHKV0n3gurxDJDUzvcPw" target="_blank" className={`w-[46px] h-[46px]  mx-3  ${colortheme == "White" ? "bg-white " : "bg-black " } rounded-full flex items-center justify-center text-center`}><FontAwesomeIcon icon={faYoutube}   className={`text-2xl  ${colortheme == "White" ? "text-black " : "text-white " }`} /></a></li>
  <li><a href="https://www.linkedin.com/company/9502268/"  target="_blank" className={`w-[46px] h-[46px] mx-3 ${colortheme == "White" ? "bg-white " : "bg-black " } rounded-full flex items-center justify-center text-center`} ><FontAwesomeIcon icon={faLinkedinIn}   className={`text-2xl ${colortheme == "White" ? "text-black " : "text-white " }`} /></a></li>
  <li><a href="https://www.instagram.com/uppout/"  target="_blank" className={`w-[46px] h-[46px] mx-3 ${colortheme == "White" ? "bg-white " : "bg-black " } rounded-full flex items-center justify-center text-center`}><FontAwesomeIcon icon={faInstagram}   className={`only:text-2xl ${colortheme == "White" ? "text-black " : "text-white " }`} /></a></li>
  </ul>
<p className={`text-xs  leading-tight text-center mb-4 ${colortheme == "White" ? "text-white " : "text-black " }`}>
©2024 UPPOUT LDA<br />
ALL RIGHTS RESERVED
</p>
</div>
      </div>


  </div>
    </>
  )
}

export default Navbar