import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import Client from './Client';
import leftarrow from '../../assets/c-arrow-left.png';
import rightarrow from '../../assets/c-arrow-right.png'
import clientimg from '../../assets/client.png';
const Clientes = () => {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  return (
    <div className='relative'>
       <Swiper
          // install Swiper modules
          modules={[Navigation, A11y]}
          spaceBetween={50}
          slidesPerView={1.1}
          centeredSlides={true}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          
        className=' h-auto'
loop='true'
        >
          <SwiperSlide className='h-auto !mr-5 '>
            {/* First Slide start */}
            
           <div className='flex flex-wrap justify-between w-full '>
           <Client imgsrc='img/Clients/01_MeuSuper.png' />
<Client imgsrc='img/Clients/02_Continente.png' />
<Client imgsrc='img/Clients/03_Bagga.png' />
<Client imgsrc='img/Clients/04_Robbialac.png' />
<Client imgsrc='img/Clients/05_GoldEnergy.png' />
<Client imgsrc='img/Clients/06_Fidelidade.png' />
<Client imgsrc='img/Clients/07_Limiano.png' />
<Client imgsrc='img/Clients/08_VacaQueRi.png' />
           </div>
      
        
        
           
            {/* First Slide end */}
          </SwiperSlide>
         

          <SwiperSlide className='h-auto !mr-5 '>
            {/* First Slide start */}
            
           <div className='flex flex-wrap justify-between w-full '>
           <Client imgsrc='img/Clients/09_GogoSqueez.png' />
<Client imgsrc='img/Clients/10_NossaAposta.png' />
<Client imgsrc='img/Clients/11_Carristur.png' />
<Client imgsrc='img/Clients/12_CNA.png' />
<Client imgsrc='img/Clients/13_Strepsils.png' />
<Client imgsrc='img/Clients/14_Durex.png' />
<Client imgsrc='img/Clients/15_Veet.png' />
<Client imgsrc='img/Clients/16_Nurofen.png' />
           </div>
      
        
        
           
            {/* First Slide end */}
          </SwiperSlide>


          <SwiperSlide className='h-auto !mr-5 '>
            {/* First Slide start */}
            
           <div className='flex flex-wrap justify-between w-full '>
           <Client imgsrc='img/Clients/17_Mar20230.png' />
<Client imgsrc='img/Clients/18_RecuperarPortugal.png' />
<Client imgsrc='img/Clients/19_Gaviscon.png' />
<Client imgsrc='img/Clients/20_Bial.png' />
<Client imgsrc='img/Clients/21_Standvirtual.png' />
<Client imgsrc='img/Clients/22_Olx.png' />
<Client imgsrc='img/Clients/23_Destak.png' />
<Client imgsrc='img/Clients/24_MDS.png' />
           </div>
      
        
        
           
            {/* First Slide end */}
          </SwiperSlide>


          <SwiperSlide className='h-auto !mr-5 '>
            {/* First Slide start */}
            
           <div className='flex flex-wrap justify-between w-full '>
           <Client imgsrc='img/Clients/25_Zomato.png' />
<Client imgsrc='img/Clients/26_VWGroup.png' />
<Client imgsrc='img/Clients/27_Carglass.png' />
<Client imgsrc='img/Clients/28_Dettol.png' />
<Client imgsrc='img/Clients/29_Carmine.png' />
<Client imgsrc='img/Clients/30_BoaVida.png' />
<Client imgsrc='img/Clients/31_Quinas.png' />
<Client imgsrc='img/Clients/32_Perrigo.png' />
           </div>
      
        
        
           
            {/* First Slide end */}
          </SwiperSlide>


          <SwiperSlide className='h-auto !mr-5 '>
            {/* First Slide start */}
            
           <div className='flex flex-wrap justify-between w-full '>
           <Client imgsrc='img/Clients/33_ConserveiraDoSul.png' />
<Client imgsrc='img/Clients/34_Docapesca.png' />
<Client imgsrc='img/Clients/blank.png' />
<Client imgsrc='img/Clients/blank.png' />
<Client imgsrc='img/Clients/35_Prio.png' />
<Client imgsrc='img/Clients/36_Oscar.png' />

<Client imgsrc='img/Clients/blank.png' />
<Client imgsrc='img/Clients/blank.png' />
           </div>
      
        
        
           
            {/* First Slide end */}
          </SwiperSlide>

        </Swiper>
        <div ref={navigationPrevRef} className='absolute h-full top-0 left-[-60px]  flex items-center  client-btn-prev hidden'><img src={leftarrow} alt='' /></div>
          <div ref={navigationNextRef}  className='absolute h-full top-0 right-[-60px]  w-[150px] flex items-center justify-end client-btn-next'><img src={rightarrow} alt='' className='h-[70px]' /></div>
    </div>
  ) 
}

export default Clientes