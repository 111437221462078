import React from 'react'
import blacklogo from '../../assets/LoadingScreen.gif'
const LoadingUI = (props) => {
  const {isLoading} = props;
  return (
    <div className={` ${isLoading? ' opacity-100 z-[999]' : ' opacity-0 -z-[9999] hidden' }   duration-700 delay-200  ease-in-out  flex  fixed h-screen z-50  w-full top-0 bg-white text-black    justify-center items-center  `}>
         <div>
         <img
              src={blacklogo}
              alt="Uppout"
              className=" w-auto h-[80vh]"
            />
          
          
         </div>
    </div>
  )
}

export default LoadingUI