import React, { useEffect, useRef, useState } from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import redgreenbtn from '../../assets/btnbg.png';
import goldenergy from '../../assets/slides/01_Slide.png';
import rightarrow from '../../assets/arrow-right.png'

const Slider = () => {
  const sliderNextRef = React.useRef(null)
  const swiperRef = useRef(null);



  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <>
      <div className="absolute bottom-5 lg:bottom-10 left-0 w-full z-0">
    <div className="swiper-pagination static z-0"></div>
  </div>

     <Swiper
      ref={swiperRef}
      // install Swiper modules
      modules={[Navigation,Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation={{
        nextEl: sliderNextRef.current,
      }}
      pagination={{
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          return '<span class="' + className + '"></span>';
        }
      }}
      scrollbar={{ draggable: true }}
    className='lg:h-full '
    loop= 'true'
    >

      <SwiperSlide className=' whitecursor h-full '>
        {/* First Slide start */}
        <div className='h-auto lg:h-full    flex flex-col justify-between'>
        <div className='relative h-[300px] lg:h-full    bg-[url("assets/slides/01Slider_Mobile.webp")] bg-center bg-no-repeat bg-[length:auto_100%] lg:bg-[length:auto_90%] mt-4 lg:mt-2'>
{/* <img
              src={goldenergy}
              alt=""
              className="mx-auto h-auto w-auto"
             
              
            />  */}
            <div onClick={goToNextSlide} className='absolute h-full right-2   flex items-start justify-end top-[25%] lg:top-[30%]'><img src={rightarrow} alt='' className='h-[60px]' /></div>
</div>
            <div className=' h-[16vh] lg:h-[18vh]  flex justify-center items-center gap-4 lg:gap-12 pt-0 hidden'>
                <p className='text-center w-[40%] lg:w-auto'>
                    <span className='text-[#00ff00] text-5xl  xl:text-[4vw] font-semibold'>100K</span><br />
                    <span className=' 	block h-[35px] lg:h-auto '>Followers (2 anos)</span>
                </p>
                <div className=' border-r border-gray-400 h-[50px] ' ></div>
                <p className='text-center w-[35%] lg:w-auto'>
                    <span className='text-[#00ff00] text-5xl xl:text-[4vw] font-semibold'>1.2M</span><br />
                    <span className='block h-[35px] lg:h-auto'>Total Likes</span>
                </p>

            </div>
            </div>
        {/* First Slide end */}
      </SwiperSlide>
     
     
      <SwiperSlide className=' whitecursor h-full '>
        {/* First Slide start */}
        <div className='h-auto lg:h-full  flex flex-col justify-between'>
        <div className='relative h-[300px] lg:h-full   bg-[url("assets/slides/02Slider_Mobile.webp")] bg-center bg-no-repeat bg-[length:auto_100%] lg:bg-[length:auto_90%]  mt-4 lg:mt-2'>
{/* <img
              src={goldenergy}
              alt=""
              className="mx-auto h-auto w-auto"
             
              
            />  */}
             <div onClick={goToNextSlide} className='absolute h-full  right-2   flex items-start justify-end top-[25%] lg:top-[30%]'><img src={rightarrow} alt='' className='h-[60px]' /></div>
</div>
      
      


            <div className=' h-[16vh] lg:h-[18vh] flex justify-center items-center gap-4 lg:gap-12 pt-0 hidden'>
                <p className='text-center w-[40%] lg:w-auto'>
                    <span className='text-[#00ff00] text-5xl  xl:text-[4vw] font-semibold'>432K</span><br />
                    <span className=' 	block h-[45px] lg:h-auto '>Alcance da ativação</span>
                </p>
                <div className=' border-r border-gray-400 h-[50px] ' ></div>
                <p className='text-center w-[30%] lg:w-auto'>
                    <span className='text-[#00ff00] text-5xl xl:text-[4vw] font-semibold'>13K</span><br />
                    <span className='block h-[45px] lg:h-auto'>Likes</span>
                </p>

            </div>
            </div>
        {/* First Slide end */}
      </SwiperSlide>

      <SwiperSlide className=' whitecursor h-full '>
        {/* First Slide start */}
        <div className='h-auto lg:h-full  flex flex-col justify-between'>
        <div className='relative h-[300px] lg:h-full   bg-[url("assets/slides/03Slider_Mobile.webp")] bg-center bg-no-repeat bg-[length:auto_100%] lg:bg-[length:auto_90%]  mt-4 lg:mt-2'>
{/* <img
              src={goldenergy}
              alt=""
              className="mx-auto h-auto w-auto"
             
              
            />  */}
             <div onClick={goToNextSlide} className='absolute h-full  right-2   flex items-start justify-end top-[25%] lg:top-[30%]'><img src={rightarrow} alt='' className='h-[60px]' /></div>
</div>
          
          

            <div className=' h-[16vh] lg:h-[18vh] flex justify-center items-center gap-4 lg:gap-12 pt-0 hidden'>
                <p className='text-center w-[40%] lg:w-auto'>
                    <span className='text-[#00ff00] text-5xl  xl:text-[4vw] font-semibold'>+120K</span><br />
                    <span className=' 	block h-[45px] lg:h-auto '>Followers</span>
                </p>
                <div className=' border-r border-gray-400 h-[50px] ' ></div>
                <p className='text-center w-[30%] lg:w-auto'>
                    <span className='text-[#00ff00] text-5xl xl:text-[4vw] font-semibold'>13K</span><br />
                    <span className='block h-[45px] lg:h-auto'>Alcance total (2024)</span>
                </p>

            </div>
            </div>
        {/* First Slide end */}
      </SwiperSlide>
  
      </Swiper>

    
    </>
  )
}

export default Slider