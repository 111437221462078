import React from 'react'
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect,useLayoutEffect, useRef } from "react";
import SplitType from 'split-type'
import {isMobile} from 'react-device-detect';

const AboutSection = (props) => {
  const {sectionno} = props;
    const aboutsec = useRef();
    gsap.registerPlugin(ScrollTrigger)
 
          useLayoutEffect(() => {
            runAnimation();
            ScrollTrigger.defaults({
           //   toggleActions: "restart pause resume pause",
              scroller: aboutsec.current
            })});

          const runAnimation = ()=>{
          let paragraphs=document.querySelectorAll(".para"); 

          if(isMobile)
{
  paragraphs.forEach((paragraph) => {
    ScrollTrigger.create({
      trigger: paragraph,
      start: "top 10%", // When the paragraph is 10% from the top of the container
      end: "bottom 8%", // When the paragraph starts leaving the bottom by 10%
      markers: false, // Optional, shows scroll markers
      onEnter: () => gsap.to(paragraph, { color: "white", fontWeight:"700", duration: 0.5 }), // Change to white
      onLeave: () => gsap.to(paragraph, { color: "#2d2d2d", fontWeight:"400",  duration: 0.5 }),  // Change back to gray on leave
      onEnterBack: () => gsap.to(paragraph, { color: "white", fontWeight:"700", duration: 0.5 }), // Change to white when scrolling back
      onLeaveBack: () => gsap.to(paragraph, { color: "#2d2d2d", fontWeight:"400", duration: 0.5 }) // Change to gray when leaving in reverse
    });
  });
}else{
  paragraphs.forEach((paragraph) => {
    ScrollTrigger.create({
      trigger: paragraph,
      start: "top 30%", // When the paragraph is 10% from the top of the container
      end: "bottom 28%", // When the paragraph starts leaving the bottom by 10%
      markers: false, // Optional, shows scroll markers
      onEnter: () => gsap.to(paragraph, { color: "white", fontWeight:"700", duration: 0.5 }), // Change to white
      onLeave: () => gsap.to(paragraph, { color: "#2d2d2d", fontWeight:"400",  duration: 0.5 }),  // Change back to gray on leave
      onEnterBack: () => gsap.to(paragraph, { color: "white", fontWeight:"700", duration: 0.5 }), // Change to white when scrolling back
      onLeaveBack: () => gsap.to(paragraph, { color: "#2d2d2d", fontWeight:"400", duration: 0.5 }) // Change to gray when leaving in reverse
    });
  });
}
 
          
          }
          useEffect (()=>{


        if(sectionno ==2){
         // aboutsec.current.scrollTop = 0;
         // runAnimation();
        }


    
    
    

          });
    
        
        

  return (
    <div ref={aboutsec} id="aboutuppout" className="text-[#2d2d2d]  text-xl    font-light lg:text-[1.7em] px-4 lg:px-28  w-full  overflow-y-scroll">
      
<div className='para  lg:h-[200px]'>

</div>
 <div className='para '>Uppout. <br />
  Disruptivamente eficazes. <br />
  Incessantemente conectados. <br />
  Obstinadamente criativos. </div>

<div className='para '>Todos os dias trazemos algo novo à agência.   <span className='hidden xl:block'></span>
Uma nova tendência, uma nova visão, uma nova ideia. <span className='hidden xl:block'></span>
Estamos sempre à procura de antecipar o futuro <span className='hidden xl:block'></span> e podem ter a 
certeza que vamos fazer parte dele. </div>

<div className='para'>Temos sempre tempo para conhecer melhor os nossos <span className='hidden xl:block'></span>
clientes. Queremos perceber exatamente a visão das suas <span className='hidden xl:block'></span>
marcas e elevá-la a novos patamares. A comunicação é uma <span className='hidden xl:block'></span>
via de dois sentidos - só quem ouve conseguirá ser ouvido. </div>

<div className='para'>Para cada desafio, contamos com a ajuda de quem está <span className='hidden xl:block'></span>
ao nosso lado. Um briefing gera um brainstorming que <span className='hidden xl:block'></span>
prontamente gera novas soluções. A criatividade é um <span className='hidden xl:block'></span>
desporto de equipa e aqui ninguém falta aos treinos. </div>

<div className='para'>Misturamos experiência, visão e irreverência. <span className='hidden xl:block'></span>
Sentido estético, sentido de humor, sentido prático e acima de <span className='hidden xl:block'></span>
tudo fazer sentido. Um cocktail deliciosamente prolífico de <span className='hidden xl:block'></span>
diferentes backgrounds, decorado com uma rodela de laranja <span className='hidden xl:block'></span>
da nossa laranjeira. </div>

<div className='para'>Não nos conformamos. Não gostamos de monotonia. <span className='hidden xl:block'></span>
Não sabemos estar quietos. O nosso logo não consegue sequer <span className='hidden xl:block'></span>
estar parado - sempre on the go, à procura do próximo desafio, <span className='hidden xl:block'></span>
do próximo briefing. </div>

<div className='para'>
Upper creativity, Outstanding results. <br /><br /><br /><br /><br /></div>



<div className='h-[650px]'></div>
</div>
       
  )
}

export default AboutSection