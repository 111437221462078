import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import {faYoutube, faInstagram, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
import blacklogo from '../../assets/LogoBlack.gif'
import whitelogo from '../../assets/LogoWhite.gif'
import { Link } from 'react-router-dom'
const Sidebar = (props) => {
    const {colortheme,page, borderstatus} = props;

    const [navStatus, setNavStatus] = useState(false)
const [popupStatus,setPopupStatus] = useState(false)
const [logoTooltip, setLogoTooltip] =useState(false)
const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 })

const onMouseMove = e =>
  setCursorPosition({ top: e.pageY+30, left: e.pageX+30 });
  return (
    <>
      <div className={`hidden  fixed h-screen   z-50   top-0    lg:block lg:w-[20%] xl:w-[15%] 2xl:w-[10%]    leftsidebar`} >
      <div className="h-full  flex flex-col items-center justify-between">
    <div className={`w-full lg:px-5 2xl:px-8  `} onMouseEnter={()=> setLogoTooltip(true)}  onMouseLeave={()=>{setLogoTooltip(false)}} onMouseMove={onMouseMove} >
    <Link to={"/"}  className='' >
      {(colortheme == "White" ) ?
       <img src={blacklogo}
       alt="Uppout"
       className="mt-8 w-full  mx-auto max-w-[80px] 2xl:max-w-[115px]"
       
     />
      :

      <img
      src={whitelogo}
      alt="Uppout"
      className="mt-8 w-full  mx-auto max-w-[80px] 2xl:max-w-[115px]"
      width={150}
      height={0}
      
    />

    }
   </Link>
   <div className={` z-50 leading-[0.2em] ${logoTooltip ? '' : 'hidden'} logotooltip`} style={{position: 'absolute', ...cursorPosition }}>
   <span className='text-sm font-semibold font-baskervville '>minuciosamente         
            </span><br />
            <span className='text-[0.6em] font-bold'>DISRUPTIVOS</span>
   </div>
    </div>

<div className={`w-full mb-20  flex justify-center py-16 ${borderstatus ? 'border-r-[0.1em]' : ''}  ${colortheme == "White" ? 'border-gray-400' : 'border-gray-400'} `}>


<ul className="w-[80px] font-semibold sidemenu">
  <li className="h-[80px] text-md  ">
    <Link to={"/about"} className={`deskmenuitem block w-[120px] pl-2 min-h-[70px] text-md leading-tight    ${colortheme == "White"? 'text-black' : 'text-white'} ${page == "about"?"active":""}`}>QUEM<br />SOMOS</Link>
    </li>
  <li className='h-[80px] text-md relative'>
  <Link href={''}  className={`deskmenuitem block  w-[120px] pl-2  min-h-[40px]  text-md leading-tight    ${colortheme == "White"? 'text-black' : 'text-white'}  ${popupStatus? "text-themeblue active" : ""} ` } onClick={()=>{setPopupStatus(!popupStatus)}} >VAMOS <br className={`hoverremove ${popupStatus ? "!hidden " : ""} `} /> FALAR</Link>

  {popupStatus ?
<div className={`absolute left-2 top-[30%] text-[0.6em] font-normal italic ${colortheme == "White"? 'text-black' : 'text-white'}`}>
<a href="mailto:geral@uppout.com">geral@uppout.com</a> <br />
<a href="tel:+351215862031">+351 215 862 031</a>
<br /><br />
<a href="https://maps.app.goo.gl/rwn3PVecz5K6GfFUA" rel="nofollow" target='_blank'>
Rua Garridas 8,<br />
1500- 618 Lisboa
</a>


</div>  
:""
}
    </li>
</ul>


  
</div>
<div className="mb-2">
  <ul className="flex justify-between mb-4">
  <li><a href="https://www.youtube.com/channel/UC4MyHKV0n3gurxDJDUzvcPw" target="_blank" className={`w-[26px] h-[26px]  rounded-full flex items-center justify-center text-center ${colortheme == "White" ? 'bg-black' : 'bg-white'}`}><FontAwesomeIcon icon={faYoutube}   className={`w-[14px] ${colortheme == "White" ? 'text-white' : 'text-black'} `} /></a></li>
  <li><a href="https://www.linkedin.com/company/9502268/" target="_blank" className={`w-[26px] h-[26px]  rounded-full flex items-center justify-center text-center ${colortheme == "White" ? 'bg-black' : 'bg-white'} `} ><FontAwesomeIcon icon={faLinkedinIn}   className={`w-[14px]  ${colortheme == "White" ? 'text-white' : 'text-black'}`} /></a></li>
  <li><a href="https://www.instagram.com/uppout/" target="_blank" className={`w-[26px] h-[26px]  rounded-full flex items-center justify-center text-center ${colortheme == "White" ? 'bg-black' : 'bg-white'} `}><FontAwesomeIcon icon={faInstagram}   className={`w-[14px]  ${colortheme == "White" ? 'text-white' : 'text-black'}`} /></a></li>
  </ul>
<p className={`text-[8px]  leading-tight text-center ${colortheme == "White" ? 'text-black' : 'text-white'}`}>
©2024 UPPOUT LDA<br />
ALL RIGHTS RESERVED
</p>
</div>
      </div>

    </div>

  


    </>
  )
}

export default Sidebar