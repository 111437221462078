import React from 'react'
import clientimg from '../../assets/client.png';
const Client = (props) => {
  const {imgsrc} = props;

  return (
    <div className='bg1-slate-300 w-1/3 lg:w-1/4 p-2 lg:p-5  relative '>
       <a href="#" className=''><img
      src={imgsrc}
      alt=""
      className="w-full"      
    /></a>
    <span className='  absolute top-[50%] left-[50%]  text-white -translate-x-1/2	-translate-y-1/2	 lg:font-bold text-sm lg:text-xl' ></span>
    </div>
  )
}

export default Client