import React from 'react'
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect,useLayoutEffect, useRef } from "react";
import { useState } from "react";


import bannerdesk from '../assets/Banner_QuemSomos.webp'
import bannermob from '../assets/Banner_QuemSomos_Mobile.webp'
import arrowdown from '../assets/Arrow_Black.png'
import arrowgreen from '../assets/Arrow_Verde.png'
import arrowupgreen from '../assets/arrow-up-green.png'
import arrowup from '../assets/arrow-up.png'
import Sidebar from './Common/Sidebar';
import Navbar from './Common/Navbar';
import Assinatura from '../assets/AssinaturaSite.png'
import AboutSection from './Common/AboutSection';
import { isMobile } from 'react-device-detect';

const About = () => {
  const [isLoading, setIsLoading] = useState(true);
const [sidebarcolor,setSidebarcolor] = useState("White");
const [borderstatus,setBorderstatus] = useState(true);
const [sectionno,setSectionno] = useState(0);
const main = useRef();
const section1 = useRef();
const section2 = useRef();
const section3 = useRef();
const [modalStatus, setModalStatus] = useState(false)
const arrowicon = useRef();
gsap.registerPlugin(ScrollTrigger)



//start scroll snap code
useLayoutEffect(() => {
  
  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    scroller: main.current
  });

//gsap.to(arrowicon.current, { rotation: "360" });
gsap.to(section1.current, {
  scrollTrigger: {
    trigger:section1.current,
    start: 'top center',
      end:'bottom center',
   // markers:true,
      toggleActions: "restart none restart none"
  }, 
onUpdate: setsection,
onUpdateParams: [1],
 });
  gsap.to(section2.current, {
    scrollTrigger: {
      trigger:section2.current,
      start: 'top center',
      end:'bottom center',
      //markers:true,
        toggleActions: "restart none restart none"
    }, 
 onUpdate: setsection,
 onUpdateParams: [2],
   });
   gsap.to(section3.current, {
    scrollTrigger: {
      trigger:section3.current,
      start: 'top center',
      end:'bottom center',
     // markers:true,
        toggleActions: "restart none restart none"
    }, 
 onUpdate: setsection,
 onUpdateParams: [3],
   });

  //return () => ctx.revert();
}, []);
//end scroll snap code

const setsection = (secno) =>{
  setSectionno(secno);
  if(secno == 1){
    setBorderstatus(false);
    setSidebarcolor("White")
    try {
      main.current.classList.add("whitesidebar")
       if(main.current.classList.contains("blacksidebar")){
      main.current.classList.remove("blacksidebar")
    }
    } catch (error) {
      
    }
    
   
  }
  else if(secno == 2 ){
    setBorderstatus(false);
    setSidebarcolor("Black")
    try {
      main.current.classList.add("blacksidebar")
       if(main.current.classList.contains("whitesidebar")){
      main.current.classList.remove("whitesidebar")
    }
    } catch (error) {
      
    }
    
   
  } else if(secno == 3){
    setBorderstatus(true);
    setSidebarcolor("White")
    main.current.classList.add("whitesidebar")
    if(main.current.classList.contains("blacksidebar")){
      main.current.classList.remove("blacksidebar")
    }
  }
    }


useEffect(() => {
  setTimeout(() => {
      setIsLoading(false);
  },1000);
  
  //gsap start
 
  // window.addEventListener('resize', handleviewport);
  // return () => {
  //   window.removeEventListener('resize', handleviewport);
  // };
}, []);


const handleviewport = () => {
  //setWindowWidth(window.innerWidth);
  console.log('resize')
  gsap.to(section2.current, {
    scrollTrigger: {
      trigger:section2.current,
     // start: 'top 0%',
      //markers:true,
        toggleActions: "play none none reset"
    }, 
 onUpdate: setsection,
 onUpdateParams: [2],
   }); 
};
  
const scrolldown = (sec) =>{
 // console.log(sec)
  sec.current.scrollIntoView({behavior: "smooth",block: "start", inline: "start"})
  
  //main.current.scrollTo(0, 130);
  }

  return (
   <>
    {/* <LoadingUI isLoading={isLoading} /> */}
  
       <main id="main" ref={main} className="w-full   aboutus" >
       <Navbar colortheme={sidebarcolor} page="about"  />
  <Sidebar  colortheme={sidebarcolor} page="about" borderstatus={borderstatus} />  
  {/* h-[calc(100vh_-_130px)] */}
<section ref={section1} className="sections relative w-full lg:w-[80%] xl:w-[85%] 2xl:w-[90%]  lg:ml-[20%] xl:ml-[15%] 2xl:ml-[10%]  overflow-y-hidden  h-[100vh]  justify-between  text-black flex flex-col px-0 lg:px-0 blackcursor" >

    <div className="  pt-28 lg:pt-0" >
    {/* h-[45%] sm:[50%] lg:h-[40%] xl:h-[40%] 2xl:h-[50%]   lg:bg-black  <video loop autoPlay muted  className="w-full h-full mx-auto lg:py-8 overflow-hidden  " lg:mt-4  lg:mr-8
    >
  <source src={aboutvideo} type="video/mp4" />
 
</video>  */}
<img src={isMobile ? bannermob : bannerdesk } alt='' className='w-full h-auto object-cover' />
</div>

<div className="h-auto pt-0 lg:pt-20 px-10 lg:px-16 mb-2 lg:mb-20">
<h1 className="text-black font-bold text-xl lg:text-3xl font-montserrat tracking-wide italic">O que fazemos<span className="text-[#00ff00] px-1">?</span></h1>

<div className="w-full xl:w-[90%] mt-5 flex gap-4 md:gap-x-8 md:gap-y-4 xl:gap-x-12 lg:gap-y-6 flex-wrap ">

{/* <div className="absolute px-6 text-center py-2 border border-gray-400 text-black text-lg lg:text-xl  tracking-wider servicebtn">comunicação digital</div> */}

  <div className="relative border leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[250px] lg:w-[350px] border-gray-400 ">
  <span className="absolute left-1 top-1 leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[250px] lg:w-[350px] text-center    border border-gray-400 text-black text-md lg:text-xl  tracking-wider ">comunicação digital</span>
  </div>

  <div className="relative border  h-[35px] lg:h-[50px] lg:leading-[40px] w-[140px] lg:w-[250px] border-gray-400">
  <span className="absolute left-1 top-1 leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[140px] lg:w-[250px] text-center    border border-gray-400 text-black text-md lg:text-xl  tracking-wider">ativações</span>
  </div>

  <div className="relative border  h-[35px] lg:h-[50px] lg:leading-[40px] w-[120px] lg:w-[200px] border-gray-400">
  <span className="absolute left-1 top-1 leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[120px] lg:w-[200px] text-center    border border-gray-400 text-black text-md lg:text-xl  tracking-wider">vídeo</span>
  </div>

  <div className="relative border h-[35px] lg:h-[50px] lg:leading-[40px] w-[120px] lg:w-[250px] border-gray-400">
  <span className="absolute left-1 top-1 leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[120px] lg:w-[250px] text-center    border border-gray-400 text-black text-md lg:text-xl  tracking-wider">estratégia</span>
  </div>

  <div className="relative border  h-[35px] lg:h-[50px] lg:leading-[40px] w-[140px] lg:w-[260px] border-gray-400">
  <span className="absolute left-1 top-1 leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[140px] lg:w-[260px] text-center    border border-gray-400 text-black text-md lg:text-xl  tracking-wider">redes sociais</span>
  </div>

  <div className="relative border  h-[35px] lg:h-[50px] lg:leading-[40px] w-[120px] lg:w-[220px] border-gray-400">
  <span className="absolute left-1 top-1 leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[120px] lg:w-[220px] text-center    border border-gray-400 text-black text-md lg:text-xl  tracking-wider">branding</span>
  </div>

  <div className="relative border  h-[35px] lg:h-[50px] lg:leading-[40px] w-[130px] lg:w-[210px] border-gray-400">
  <span className="absolute left-1 top-1 leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[130px] lg:w-[210px] text-center    border border-gray-400 text-black text-md lg:text-xl  tracking-wider">eventos</span>
  </div>

  <div className="relative border  h-[35px] lg:h-[50px] lg:leading-[40px] w-[220px] lg:w-[310px] border-gray-400">
  <span className="absolute left-1 top-1 leading-[25px] h-[35px] lg:h-[50px] lg:leading-[40px] w-[220px] lg:w-[310px] text-center    border border-gray-400 text-black text-md lg:text-xl  tracking-wider">ponto de venda</span>
  </div>

</div>

</div>

<div className="text-center h-[20px] ">
 <img
          src={arrowdown}
          alt=""
          className="w-[80px] cursor-blackcur rotate-180 mx-auto mb-3"
          
          ref={arrowicon}
onClick={()=>{scrolldown(section2)}}
        />
 </div>
  </section>



<section ref={section2} className="section2 sections w-full bg-[#111111] lg:w-[80%] xl:w-[85%] 2xl:w-[90%]  lg:ml-[20%] xl:ml-[15%] 2xl:ml-[10%]   h-[100vh]  justify-between text-white flex flex-col whitecursor nnp" >

    <div  className="w-full  h-[100vh] ">
     
    <div className='h-full flex flex-col pb-0 relative'>
        <div className='lg:absolute top-0 text-white  text-2xl lg:text-3xl xl:text-4xl  pl-8 pt-28 lg:pt-10'>
        <p className=' font-bold leading-normal italic mb-4 lg:mb-0'>Manifesto</p>
       
       
        </div>

<AboutSection sectionno={sectionno} />
       


        </div>


</div>
      </section>





      <section ref={section3}   className="sections4 relative w-full bg-white lg:w-[80%] xl:w-[85%] 2xl:w-[90%]  lg:ml-[20%] xl:ml-[15%] 2xl:ml-[10%]    h-[100vh]  justify-between text-white flex flex-col blackcursor" >


      <div className="text-center h-[20px] ">
 <img
          src={arrowgreen}
          alt=""
          className="w-[80px] cursor-blackcur  mx-auto mt-2"
          
          ref={arrowicon}
onClick={()=>{scrolldown(section2)}}
        />
 </div>
<div className="overflow-hidden h-[100vh] px-10 lg:px-20 pr-10  flex flex-col justify-center items-center ">


<div className='h-[60vh] flex justify-center items-center'>
<img src={Assinatura} alt="" className='lg:h-full mb-24 lg:mb-0 mx-auto ' />
</div>
<div className="w-full">
<div className='flex justify-center lg:justify-end pt-10'>
            <button className='w-auto lg:max-w-sm bg-white text-[#0000ff] font-bold text-sm lg:text-lg hover:text-xl hover:font-baskervville hover:italic hover:lowercase hover:text-black hover:tracking-wider leading-tight px-6 lg:px-8 py-1 text-center  relative bordery cornerwhite text-nowrap' onClick={()=>setModalStatus(!modalStatus)}>DIZ-NOS <span className='block lg:hidden'></span> O QUE PRECISAS!</button>
            {/* <button className={`w-full lg:max-w-sm bg-white text-[#0000ff] font-bold text-md lg:text-lg hover:text-xl hover:font-baskervville hover:italic hover:lowercase hover:text-black hover:tracking-wider px-8 lg:px-16 py-2 bg-[url('assets/btnbg.png')] bg-[length:100%_100%] text-center `} onClick={()=>setModalStatus(!modalStatus)}>DIZ-NOS O QUE PRECISAS!</button> */}
        </div>
</div>
</div>

{modalStatus ?
       
         
<div className='absolute w-full h-full bg-white/80 '>
<div className='text-black absolute  bottom-32 lg:bottom-20 max-lg:left-10 lg:right-10 w-[80%] lg:w-full  lg:max-w-2xl xl:max-w-3xl  bg-white bordery cornerwhite '>
               <a className='absolute right-0 top-2 flex justify-end px-2 pt-1 cursor-pointer' onClick={()=>setModalStatus(!modalStatus)} >
              <svg className="h-8 w-8 group-hover:rotate-180 transition duration-300 ease-in-out" fill="white"
              viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" aria-hidden="true">
              <path d="M6 18L18 6M6 6l12 12" />

              
            </svg>
              </a>
              <div className='w-full flex flex-col lg:flex-row justify-center items-center lg:gap-20 pt-5 pb-6 lg:pb-12'>
              <p className='w-full text-[#0000ff] font-bold leading-tight text-md lg:text-3xl flex px-6 lg:justify-end items-center'>
              <span>
              DIZ-NOS O QUE<br />
              PRECISAS! 
              </span>
              </p>
              <p className='w-full leading-tight italic text-md lg:text-2xl flex  px-6 lg:justify-start items-start pt-4 lg:pt-4'>
                <span>
                <a href='mailto:geral@uppout.com'>geral@uppout.com</a> <br /><span className='block h-[15px] lg:hidden' ></span>
              <a href='tel:+351215862031'>+351 215 862 031</a><br /><span className='block h-[15px] ' ></span>
              <a href="https://maps.app.goo.gl/rwn3PVecz5K6GfFUA" rel="nofollow" target='_blank' className='text-md italic lg:text-sm'>Rua Garridas 8,<br className='block lg:hidden' /> 1500-618 Lisboa</a>
                </span>
             
              </p>
              </div>
              </div></div>

         :""
        }
        
</section>


    
    
   </main>
     
      
   
   </>
  )
}

export default About